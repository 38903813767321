export function keyedTransformer(
  transformer, from, to = from, transformOnUndefined = false, dflt
) {
  return function (source, result) {
    const data = (source && typeof source[from] !== 'undefined' && source[from] !== null && source[from] !== '')
      ? source[from]
      : dflt;

    if (!transformOnUndefined && typeof data === 'undefined') {
      return result;
    }

    return {
      ...result,
      [to]: transformer(data),
    };
  };
}

export function symetricalKeyTransformers(fromHandler, toHandler, fromKey, toKey = fromKey) {
  return [
    keyedTransformer(fromHandler, fromKey, toKey),
    keyedTransformer(toHandler, toKey, fromKey),
  ];
}

export function sameTransformer(data) {
  return data;
}

export function composeTransformers(...transformers) {
  const prepared = transformers.reverse();

  return function (source) {
    return prepared.reduce((acc, transform) => transform(source, acc), {});
  };
}
