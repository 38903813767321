<template lang="pug">
//- control-select(
//-   :multiple="multiple"
//-   :value="internal"
//-   @input="input"
//-   @close="close"
//-   @open="open"
//-   trackBy="id"
//-   label="caption"
//-   :placeholder="placeholder || filter.caption"
//-   :options="options"
//-   v-bind="$attrs"
//- )
vue-selection-controller(
  v-slot="controller",
  :value="internal"
  @input="val => { val && val[0] ?  input(val) : input([]); close(); $emit('filtrate', internal)}"
  @close="close"
  @open="open"
  :options="filter.props && filter.props.items"
  :key-getter="({id}) => id",
  :multiple="true"
)
  row(justify="center" appearance="nowrap")
    cell(
      cols
      v-for="option in controller.options",
      :key="option.key",
    )
      control-button(
        @click.prevent="() => { controller.change(option.value); }"
        :styling="selected.includes(option.value.id) ? 'primary' : 'secondary'"
        size="md",
        :disabled="!options.includes(option.value)"
        type="button",
      )
        icon(
          size="lg",
          v-if="option.value.id == '0001000-air'",
          :name="'airplanemode_active'",
        )
        icon(
          size="lg",
          v-else-if="option.value.id == '0002000-sea'",
          :name="'anchor'",
        )
        icon(
          size="lg",
          v-else-if="option.value.id == '0003000-rails'",
          :name="'train'",
        )

  //- template(#option="{ option }")
  //-   div
  //-     icon(:name="option.icon")
  //-     span {{ " " + option.title }}
  //- template(#singleLabel="{ option }")
  //-   div
  //-     icon(v-if="option.icon", :name="option.icon")
  //-     span(v-else)
  //-     span {{ " " + option.title }}

</template>
<script>
import SelectWidget from './SelectWidget';

export default {
  extends: SelectWidget,
  name: 'MultiselectWidget',
  props: {
    multiple: {
      default: true,
    },
  },
};
</script>
