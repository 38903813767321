import {
  join, evolve, map, pipe, filter, not,
} from 'ramda';
import { isEmpty } from '@aspectus/vue-utils';
import {
  keyedTransformer as k,
  sameTransformer,
  composeTransformers,
} from '@md/transformers';
import { parametersMiddleware } from '@aspectus/resource';

const toNumber = value => {
  console.log('toNumber', value, Number((value || '').replace(',', '.')));
  return value ? Number((value || '').replace(',', '.')) : value
};
const checkEmpty = value => (isEmpty(value) ? undefined : value);
const checkUrl = value => (value && value.startsWith('data') ? value : undefined);
const ar = value => (Array.isArray(value) ? value : [value]);
// const selectTo = curry((filter, id) => filter.props.items.find(({ id }) => id === id));
const selectFrom = ({ id } = {}) => id || null;
const phoneTransformer = phone => phone.replace(/[^\d]/g, '');
const selectFromBoolean = ({ id } = {}) => id;
const multiselectFrom = pipe(ar, map(selectFrom), filter(pipe(isEmpty, not)));
const multisameFrom = pipe(ar, map(sameTransformer), filter(pipe(isEmpty, not)));
const fileFrom = pipe(ar, map(selectFrom), join(' '));
const filesFrom = pipe(ar, map(selectFrom));
const dateTo = date => (date ? new Date(date) : null);
const dateFrom = date => new Date(date).toISOString();
const dateOnlyFrom = date => {
  const dateTransform = new Date(date);
  return `${dateTransform.getFullYear()}-${dateTransform.getMonth() + 1}-${dateTransform.getDate()}`;
};
const dateMonthFrom = date => (date === 'None' ? null : `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-02T00:00:00`);
const dateRangeFrom = evolve({ min: dateFrom, max: dateFrom });
const dateOnlyRangeFrom = evolve({ min: dateOnlyFrom, max: dateOnlyFrom });
const combinedInputFrom = map(composeTransformers(
  k(selectFrom, 'element', 'id'),
  k(sameTransformer, 'search')
));

export const simplifiers = {
  photo: key => k(pipe(sameTransformer, checkEmpty, checkUrl), key),
  file: key => k(fileFrom, key),
  files: key => k(filesFrom, key),
  sameNull: (key, toKey, transformOnUndefined, dflt) => k(sameTransformer, key, toKey, transformOnUndefined, dflt),
  sameEmpty: key => k(pipe(sameTransformer), key),
  same: key => k(pipe(sameTransformer, checkEmpty), key),
  number: key => k(pipe(toNumber), key),
  numberNull: (key, toKey, transformOnUndefined, dflt) => k(toNumber, key, toKey, transformOnUndefined, dflt),
  multisame: key => k(pipe(multisameFrom, checkEmpty), key),
  phoneTransform: key => k(pipe(phoneTransformer, checkEmpty), key),
  search: key => k(pipe(sameTransformer, checkEmpty), key),
  select: key => k(pipe(selectFrom, checkEmpty), key),
  selectBoolean: key => k(pipe(selectFromBoolean), key),
  selectSimple: key => k(pipe(selectFrom), key, key, true),
  multiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  range: key => k(pipe(sameTransformer, checkEmpty), key),
  dateTo: key => k(pipe(dateTo), key),
  date: key => k(pipe(dateFrom, checkEmpty), key),
  dateMonth: key => k(pipe(dateMonthFrom, checkEmpty), key),
  dateRange: key => k(pipe(dateRangeFrom, checkEmpty), key),
  onlyDateRange: key => k(pipe(dateOnlyRangeFrom, checkEmpty), key),
  autocompleteMultiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: key => k(pipe(combinedInputFrom, checkEmpty), key),
};

export const filterAPIMiddleware = transformer => parametersMiddleware(parameters => ({
  ...parameters,
  filters: JSON.stringify(transformer(parameters.filters)),
}));
